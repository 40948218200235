import { createSlice } from '@reduxjs/toolkit'
import query from '@utils/query'

export const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        a: null
    },
    reducers: {
        setToken: (state, { payload }) => {
            state.a = payload
        },
        logout: state => {
            state.a = ''
        }
    }
})

export const { logout, setToken } = authSlice.actions

export default authSlice.reducer