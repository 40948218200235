import "./App.css";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Orders from "layouts/Orders";
import { LoginRequired } from "services/auth";
import PackagingSlip from "./pages/PackagingSlip"
import React from "react";

const LoginDash = () => (
  <LoginRequired>
    <Dashboard />
  </LoginRequired>
);

const loginRequired = (component) => <LoginRequired>{React.createElement(component)}</LoginRequired>
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/orders" element={loginRequired(Orders)} />
        <Route path="/" element={<LoginDash />} />
        <Route path="/packagingSlip" element={<PackagingSlip />} />
      </Routes>
    </Router>
  );
}

export default App;
