import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import query from "./utils/query"

import store from "./store"
import { Provider } from "react-redux"

import { CookiesProvider } from "react-cookie";

// have the ability to pass in an appConfig from server-side
if (!window.appConfig) {
  console.warn("no app config given")
}

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
