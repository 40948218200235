import React, { Component } from 'react'
import DashboardLayout from '../layouts/DashboardLayout'

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Copyright from '@components/Copyright'
import { connect } from 'react-redux';
import { getOrders } from 'services/ebay';
import { rootShouldForwardProp } from '@mui/material/styles/styled';

function Dashboard({ token, ...props }) {
  return (
    <DashboardLayout title="Dashboard">

      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <p>Orders</p>
          
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <p>Recent Deposits</p>
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            {/* <Orders /> */}
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </DashboardLayout>
  )
}

export default connect((state) => ({
  token: state.auth.a
}))(function ConnectedDashboard(props) {
  return <Dashboard {...props} />;
})