export default qs()

export function qs() {
    let obj = {}
    let href = window.location.href
    let query = href.substring(href.indexOf('?')+1, href.length)
    
    if (query.indexOf('#') !== -1) {
      query = query.substring(0, query.indexOf('#'))
    }
  
    let vars = query.split('&')
    for (let i=0; i<vars.length; i++) {
      let pair = vars[i].split('=')
      let key = decodeURIComponent(pair[0])
      let value = decodeURIComponent(pair[1])
      obj[key] = value || true // true so that "?x"; if (query.x) {}
    }
    return obj
  }
  