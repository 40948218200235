import * as React from "react";
import Link from "@mui/material/Link";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Title from "./Title";
import DashboardLayout from "./DashboardLayout";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "@components/Copyright";
import { connect, useSelector } from 'react-redux';
import { getOrders, getItem, orderFulfillmentStatus, FULFILLED, NOT_STARTED, IN_PROGRESS } from 'services/ebay';
import OrderFilters from '@components/OrderFilters'
import { getOrderFilters } from "redux/filterSlice";


const multiStatus = (...status) => status.join('|')
function Orders({ token, ...props }) {
  // Default orders
  const [orders, setOrders] = React.useState({ orders: [] })

  // Fetch filters from redux
  const orderFilters = useSelector(getOrderFilters)
  const { limit } = orderFilters

  // Find out which filters are enabled
  const selectedFilters = orderFilters.orderfulfillmentstatus
  const enabledFilters = Object.entries(selectedFilters).filter(([filter, enabled]) => enabled).map(([filter, enabled]) => filter)
  // Turn it into format ebay understands (filter separated by "|")
  const orderFulfillmentStatus = multiStatus(...enabledFilters)


  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getOrders(token, { orderFulfillmentStatus, limit, })
      setOrders(data)
    }

    fetchData()
      .catch(console.error)
  }, [orderFilters])

  const weights = {
    "133774762559": "6 lb",
    "133820628394": "1 lb",
    "133753352067": "2 lb",
    "133779295367": "5 lb",
    "133812785251": "3 lb"
  }

  function findWeight({ legacyItemId, ...lineItem }, ask) {
    let weight = weights[legacyItemId]
    let msg = `${lineItem.title} - ${legacyItemId}`
    if (weight === undefined && ask) {
      debugger
      weight = window.prompt(msg)
      weights[legacyItemId] = weight
    }
    return weight
  }

  function generateTimestamp(date) {
    let d = new Date(date)
    let pad = (val) => ('0' + val).slice(-2)
    let m = pad(d.getMonth() + 1)
    let DD = pad(d.getDate())
    let YYYY = d.getFullYear()
    let H = pad(d.getHours())
    let M = pad(d.getMinutes())
    let S = pad(d.getSeconds())
    return `${m}/${DD}/${YYYY} ${H}:${M}:${S}`
  }

  function generateOrderQueryString(order) {
    console.log('generate order query string for', order.orderId)
    if (!order) return
    let date = new Date(order.creationDate)

    let qs = {
      name: order.fulfillmentStartInstructions[0].shippingStep.shipTo.fullName,
      address1: order.fulfillmentStartInstructions[0].shippingStep.shipTo.contactAddress.addressLine1,
      city: order.fulfillmentStartInstructions[0].shippingStep.shipTo.contactAddress.city,
      state: order.fulfillmentStartInstructions[0].shippingStep.shipTo.contactAddress.stateOrProvince,
      zip_code: order.fulfillmentStartInstructions[0].shippingStep.shipTo.contactAddress.postalCode,
      order_number: order.orderId,
      order_date: generateTimestamp(date),
      customer_id: order.buyer.username,
      items: order.lineItems.map(lineItem => ({
        id: lineItem.legacyItemId,
        description: lineItem.title,
        weight: findWeight(lineItem, true),
        qty: lineItem.quantity,
      })),
      order_type: 'EBAY',
    }

    let serialize = function (obj, prefix) {
      let str = [],
        p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          str.push((v !== null && typeof v === "object") ?
            serialize(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
      return str.join("&");
    }

    return serialize(qs)
  }

  const memoOrders = React.useMemo(() => {
    if (orders.errors) {
      console.log(orders.errors)
      return (
        <>
          <TableRow>
            <TableCell component='th' scope="row">Error(s)</TableCell>
          </TableRow>
          <TableRow>
            {Object.keys(orders.errors[0]).map(key =>
              <TableCell key={key} component='th' scope="row">{key}</TableCell>
            )}
          </TableRow>
          {orders.errors.map(error =>
            <TableRow key={error.errorId}>
              {Object.values(error).map(value => 
                <TableCell key={value} component='th' scope="row">{Array.isArray(value) ? value[0].name : value}</TableCell>
              )}
            </TableRow>
          )}
        </>
      )
    }

    return orders.orders && orders.orders.map((order => (
      <TableRow key={order.orderId}>
        <TableCell component='th' scope="row">{order.salesRecordReference}</TableCell>
        <TableCell component='th' scope="row">{order.orderId}</TableCell>
        <TableCell component='th' scope="row">{order.buyer.username}</TableCell>
        <TableCell component='th' scope="row">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Qty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order.lineItems.map(lineItem => {
                return (
                  <TableRow key={order.orderId + lineItem.legacyItemId}>
                    <TableCell>{lineItem.legacyItemId}</TableCell>
                    <TableCell>{lineItem.title}</TableCell>
                    <TableCell>{findWeight(lineItem)}</TableCell>
                    <TableCell>{lineItem.quantity}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableCell>
        <TableCell component='th' scope="row">{order.pricingSummary.priceSubtotal.value}</TableCell>
        <TableCell component='th' scope="row">
          <Link
            href={`#`}
            onClick={function (event) {
              event.preventDefault();
              event.stopPropagation();
              let href = `https://packaging.ceasupply.com/packagingSlip?${generateOrderQueryString(order)}`
              window.open(href, 'targetWindow',
                `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=1200px,
                    height=800px`);
              return false;
            }}>Generate</Link></TableCell>
      </TableRow>
    )))
  }, [orders])

  return (
    <DashboardLayout title="orders">
      <Grid container>
        {/* Chart */}
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>Orders</p>
            <div>
              Filters:
              <OrderFilters />
            </div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} >
                <TableHead>
                  <TableRow>
                    <TableCell>Sale #</TableCell>
                    <TableCell>Order Id</TableCell>
                    <TableCell>Buyer Username</TableCell>
                    <TableCell>Item(s)</TableCell>
                    <TableCell>Subtotal</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memoOrders}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}


export default connect((state) => ({
  token: state.auth.a
}))(function ConnectedOrders(props) {
  return <Orders {...props} />;
})