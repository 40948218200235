import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'redux/appSlice'
import filtersReducer from 'redux/filterSlice'
import userReducer from 'redux/userSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    filters: filtersReducer,
    user: userReducer
  },
  devTools: process.env.NODE_ENV != 'production'
})

export default store