import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
    name: 'filters',
    initialState: {
        orderfulfillmentstatus: {
            IN_PROGRESS: true,
            FULFILLED: false,
            NOT_STARTED: true,
        },
        limit: 2,
    },
    reducers: {
        setNotStarted: (state) => {
            state.orderfulfillmentstatus.NOT_STARTED = true
            state.orderfulfillmentstatus.FULFILLED = false
        },
        setFulfilled: (state) => {
            state.orderfulfillmentstatus.NOT_STARTED = false
            state.orderfulfillmentstatus.FULFILLED = true
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        }
    }
})


export const getOrderFilters = state => state.filters
export const { setNotStarted, setFulfilled, setLimit } = filterSlice.actions
export default filterSlice.reducer