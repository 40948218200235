import { connect } from "react-redux";

import query from "@utils/query";
import { setToken } from "redux/appSlice";
import { bindActionCreators } from "redux";
import { styled } from "@mui/system";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";

import ebayLogo from "./ebay_logo.png";
import Cookies from 'js-cookie'

const StyledButton = styled(Button)(({ theme }) => {
  console.log(theme);
  return `
  textTransform: none;
  display: flex;
  margin-top: ${theme.spacing(2)};
  align-items: center;
  background-color: #1976d2;
  color: #fff;
  &:hover {
    background-color: #1565c0;
  }
`;
});

// ({
//   center: {
//     display: "flex",
//     justifyContent: "center",
//   },
//   button: {
//     textTransform: "none",
//     marginTop: theme.spacing(10),
//     display: "flex",
//     alignItems: "center",
//     boxShadow: theme.shadows[3],
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//     transition: "background-color 0.5s",
//     "&:hover": {
//       backgroundColor: theme.palette.primary.dark,
//       transition: "background-color 0.5s",
//       cursor: "pointer",
//     },
//   },
// });

const EbayButton = (props) => {
  return (
    <StyledButton variant="contained" {...props}>
      <Avatar
        src={ebayLogo}
        sx={{
          "& img": {
            height: "auto",
          },
        }}
      />
      <Typography component="p" variant="h6">
        Sign in with eBay
      </Typography>
    </StyledButton>
  );
};

let ebayAuthentication = ({ setToken }) => {
  return {
    authenticate: function () {
      console.log('checking ebay auth (GET `code` param)')
      // Right now the only way we have to detect a login is via an eBay code being passed in via GET
      // In theory, we can have whatever methods here and enable/disable them via some given appConfig
      if (query.code) {
        // store the query code in redux
        setToken(decodeURIComponent(atob(query.code)));

        // redirect back to
      }
      return !!query.code;
    },
  };
};

const cookieAuthentication = ({ setToken }) => {
  return {
    authenticate: () => {
      console.log('checking cookie auth')
      // Check if we have a token set via cookie
      let access_token = Cookies.get('access_token')
      if (access_token) {
        console.log('found', access_token)
        setToken(access_token)
      }
      return !!access_token
    }
  }
}

/**
 * Returns the avaiable login methods
 *
 * @returns array<func> array of loginMethod functions
 */
let getLoginMethods = () => {
  let loginMethods = [cookieAuthentication, ebayAuthentication];

  return loginMethods;
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.a,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setToken }, dispatch);


/**
 * Login Required Component
 */
export const LoginRequired = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ isLoggedIn, setToken, ...props }) => {

  // What do we want to do when login is required?
  const onLoginRequired = (props) => {
    const redirectToAuthURL = () => {
      window.location.href = "https://packaging.ceasupply.com/login";
      // window.location.href = "https://auth.ebay.com/oauth2/authorize?client_id=SavantCo-SavantCR-PRD-11da63fc5-b63075c5&redirect_uri=Savant_Coding-SavantCo-Savant-mnuvdh&response_type=code&state=None&scope=https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope+https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope%2Fsell.fulfillment"
    };

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: 'center',
          height: '100%',
          backgroundColor: '#eee',
        }}
      >
        <Paper sx={{
          border: '1px solid #ddd',
          boxShadow: 2,
          textAlign: 'center',
          color: '#333',
          margin: "8px",
          padding: '2em',
        }}>
          <p>Please login to proceed</p>
          <EbayButton onClick={redirectToAuthURL} />
        </Paper>
      </Box>
    );
  };

  if (!isLoggedIn) {
    // go through our authentication methods and see if the user is already auth'd via one of them
    let loginMethods = getLoginMethods();

    for (let i = 0; i < loginMethods.length; i++) {
      if (isLoggedIn) break;
      let loginProvider = loginMethods[i];
      if (loginProvider({
        'setToken': function (token) {
          console.log('setting cookie access_token')
          Cookies.set('access_token', token, { expires: 1,})
          setToken(token)
        }
      }).authenticate()) {
        isLoggedIn = true;
      }
    }
  }

  return isLoggedIn ? props.children : onLoginRequired(props);
});
