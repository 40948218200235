export const NOT_STARTED = "NOT_STARTED";
export const FULFILLED = "FULFILLED";
export const IN_PROGRESS = "IN_PROGRESS";

export const orderFulfillmentStatus = {
  FULFILLED,
  NOT_STARTED,
  IN_PROGRESS,
}

const defaultOptions = {
  orderIds: [],
  filter: [],
  limit: "50",
  offset: 0,
  fieldGroups: [],
};

export const getOrders = async (token, {
  orderIds = [],
  filter = [],
  limit = "50",
  offset = 0,
  fieldGroups = [],
  // "orderFulfillmentStatus" is a fast shortcut to applying a filter
  orderFulfillmentStatus = undefined
} = {}) => {
  let options = {
    orderIds, filter, limit, offset, fieldGroups, orderFulfillmentStatus
  }
  // Build the order filter
  if (orderFulfillmentStatus) {
    filter.push({ 'orderfulfillmentstatus': orderFulfillmentStatus })
  }

  // Build our query object
  let query = {
    filter,
    limit,
    offset,
    fieldGroups,
  };

  if (orderIds.length) {
    query = { orderIds };
  }

  let queryString = Object.entries(query)
    .filter(([key, value]) => value.length && value || value)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        value = value.map(o => Object.entries(o).reduce((prev, [k, v]) => prev ? `${k}:{${v}},${prev}` : `${k}:{${v}}`, '')).toString()
      }
      return `${key}=${encodeURIComponent(value)}`
    })
    .join("&");

  const url = "/sell/fulfillment/v1/order?" + queryString;
  const bearer = `Bearer ${token}`;
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: new Headers({
      Authorization: bearer,
      Accept: "application/json",
    }),
  });
  const data = await response.json();
  return data;
};

export const getItem = async (token, item_id) => {
  const path = `/buy/browse/v1/item/${item_id}`
  const response = await API.get(path, token)
  const json = await response.json()
  return json
}

export const getItemByLegacyId = async ({ 
  token, 
  legacy_item_id, 
  legacy_variation_id,
  legacy_variation_sku,
  fieldgroups
} = {})=> {
  if (!token) {
    throw new TypeError('getItemByLegacyId required `token`')
  }

  if (!legacy_item_id) {
    throw new TypeError('getItemByLegacyId requires `legacy_item_id`')
  }
  const path = `/buy/browse/v1/item/get_item_by_legacy_id?${legacy_item_id}`
  const response = await API.get(path, token)
  const data = await response.json()
  return data
}

// This gets used internally by this class (could this be static instead?)
// const API = new API()


function API(token) {

  this.get = async (url, token) => await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    })
  })
  this.browse = {}
  this.browse.item = {
    getItem: getItem.bind(this, token)
  }
  this.sell = {
    fulfillment: {
      getOrders: getOrders.bind(this, token)
    }
  }
}

// export const API = (token) => new API(token)