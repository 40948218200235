import React, { Component } from 'react'
import { connect } from 'react-redux';

function PackagingSlip () {

}


export default connect((state) => {})(function ConnectedPackagingSlip(props) {
    return <PackagingSlip {...props}/>;
  })
