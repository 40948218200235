import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { FULFILLED, NOT_STARTED, IN_PROGRESS } from '@services/ebay'
import { getOrderFilters, setFulfilled, setNotStarted, setLimit} from 'redux/filterSlice';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Checkboxes() {
    const selectedFilters = useSelector(getOrderFilters)
    const { limit } = selectedFilters

    const dispatch = useDispatch()

    const options = {
        NOT_STARTED: 'Not Started',
        FULFILLED: 'Fulfilled',
        IN_PROGRESS,
    }
    const handleStatusChange = (option) => (event) => {
        let checked = event.target.checked
        if (option === FULFILLED && checked === true || option === IN_PROGRESS && !checked) {
            dispatch(setFulfilled())
        } else {
            dispatch(setNotStarted())
        }
    }

    const handleLimitChange = (event) => {
        dispatch(setLimit(event.target.value))
    }
    

    return (
        <div>
            {Object.entries(options).map(([val, label], idx) => (
                <FormControlLabel key={val} control={<Checkbox onChange={handleStatusChange(val)} value={val} checked={selectedFilters.orderfulfillmentstatus[val]} disabled={val === IN_PROGRESS} />} {...{ label }} />)
            )}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={limit}
                label="Age"
                onChange={handleLimitChange}
            >
                {[...Array(10).keys()].map(k => (
                    <MenuItem key={k} value={k+1}>{k+1}</MenuItem>
                )
                )}
            </Select>
        </div>
    );
}